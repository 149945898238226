import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Question from "../../components/question";
import AnimatedButton from "../../components/animatedButton";
import {
  checkAnswerStart,
  playQuizStart,
  submitQuizStart,
} from "../../redux/quiz/quiz.action";
import {
  selectCurrentQuestionNo,
  selectCurrentStartTime,
  selectCurrentSubmission,
  selectQuizDetails,
} from "../../redux/quiz/quiz.selector";
import "./index.css";
import { ClipLoader } from "react-spinners";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import CustomModal from "../../components/customModal";
import { fetchCurrentUserScoreStart } from "../../redux/user/user.action";

let socket;
const Quiz = () => {
  const quizDetails = useSelector(selectQuizDetails);
  const currentQuestionNo = useSelector(selectCurrentQuestionNo);
  const currentSubmission = useSelector(selectCurrentSubmission);
  const location = useLocation();
  const dispatch = useDispatch();
  const startTime = useSelector(selectCurrentStartTime);
  const nav = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  useEffect(() => {
    if (
      currentQuestionNo == null ||
      currentQuestionNo == undefined ||
      !quizDetails
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [quizDetails]);

  // useEffect(() => {
  //   socket = io("https://treasurehuntapi.friftysearch.com:4000/answer");
  //   socket.on("connect", () => {
  //     console.log("connected");
  //   });
  //   socket.on("Welcome", (msg) => console.log(msg));
  //   socket.on("disconnect", () => {
  //     console.log("disconnected");
  //   });
  //   socket.on("answer-saved", (nextQuestionNo) => {
  //     dispatch(updateCurrentQuestionNoStart(nextQuestionNo));
  //     setStartTime(Date.now());
  //   });
  //   socket.on("wrong-answer", () => {
  //     toast.error("Wrong answer,try again", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   });
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    if (currentSubmission && currentSubmission.isSubmitted) {
      nav("/dashboard");
    }
  }, [currentSubmission]);

  useEffect(() => {
    if (!location.state) {
      nav(-1);
    }
    if (!quizDetails) dispatch(playQuizStart());
  });

  const handleNext = (res) => {
    dispatch(checkAnswerStart({ ...res, startTime, _id: quizDetails._id }));
  };
  const handleSubmitQuiz = () => {
    const data = {
      _id: quizDetails._id,
      user: quizDetails.user,
      weekId: quizDetails.weekId._id,
    };
    dispatch(fetchCurrentUserScoreStart());
    dispatch(submitQuizStart(data));
  };

  const handleBack = (e) => {
    e.preventDefault();
    nav("/dashboard");
  };

  const instructionModalContent = (
    <div className="flex-1  bg-black p-4 ">
      <button className="block fit-content ml-auto text-white">
        <AiOutlineCloseCircle
          onClick={closeModal}
          className="mb-2 cursor-pointer"
          size={30}
        />
      </button>
      <div className="h-full w-full text-white mb-4">
        <h1 className="text-xl font-bold my-4 text-center ">
          Instructions regarding acceptance of answer
        </h1>
        <br />
        <ol className="mx-auto text-sm  w-3/4">
          <li className=" ">
            1. If the question demands numeric answer,and the answer is a whole
            number then it should not contain decimal places. <br />
            <span className="text-fun-orange">
              For example,if answer is 100 then 100.0 or 100.000 or 0100 or
              00100.00 are not acceptable
            </span>
          </li>
          <br />
          <li className="">
            2. If answer contains decimal places then there should not be any
            rounding off while submitting. <br />
            <span className="text-fun-orange">
              For example,if answer id 100.68 then 101 or 100.7 or 100 is not
              acceptable
            </span>
          </li>
          <br />
        </ol>
      </div>
    </div>
  );

  return (
    <div className="quiz grid grid-cols-5 relative h-screen overflow-hidden">
      <div className="hidden lg:flex lg:col-span-2 pb-8  bg-retro-black flex-col px-8 h-screen sticky top-0">
        <main className="flex-1 pt-48 grid place-items-center">
          <button
            className="absolute flex items-center left-8 top-10 bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-retro-gray"
            onClick={handleBack}
          >
            <AiOutlineDoubleLeft />
            Back
          </button>
          <div className="h-full w-full text-white mb-4 ">
            <h1 className="text-xl font-bold my-4  ">
              Instructions regarding acceptance of answer
            </h1>
            <br />
            <ol className="text-sm  w-3/4">
              <li className=" ">
                1. If the question demands numeric answer,and the answer is a
                whole number then it should not contain decimal places. <br />
                <span className="text-fun-orange">
                  For example,if answer is 100 then 100.0 or 100.000 or 0100 or
                  00100.00 are not acceptable
                </span>
              </li>
              <br />
              <li className="">
                2. If answer contains decimal places then there should not be
                any rounding off while submitting. <br />
                <span className="text-fun-orange">
                  For example,if answer id 100.68 then 101 or 100.7 or 100 is
                  not acceptable
                </span>
              </li>
              <br />
            </ol>
          </div>
        </main>
        <aside>
          <p className="text-transparent-gray mb-4">
            {currentQuestionNo &&
              `${currentQuestionNo - 1} / ${
                quizDetails?.weekId?.questions.length
              } Answered`}
          </p>
          <div className="progress-bar-container border border-transparent-gray w-full h-4 rounded-full">
            <div
              style={{
                width: `${
                  ((currentQuestionNo - 1) * 100) /
                  quizDetails?.weekId?.questions.length
                }%`,
              }}
              className="h-full rounded-full progress-bar-indicator"
            ></div>
          </div>
        </aside>
      </div>
      <div className=" col-span-5 px-4 lg:px-0 lg:col-span-3 flex justify-center items-center">
        <div className="w-full lg:w-3/4">
          <section>
            {/* {
              questions && questions.map((question , index) => <Question key={index} question={question} />)

            } */}

            <button
              className="md:hidden absolute top-4 left-4 flex items-center bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-retro-gray"
              onClick={handleBack}
            >
              <AiOutlineDoubleLeft />
              Back
            </button>
            <button
              className="md:hidden absolute top-4 right-4 flex items-center bg-gray-600 text-white py-2 px-4 rounded-md hover:bg-retro-gray"
              onClick={openModal}
            >
              Instructions
            </button>
            <CustomModal
              modalIsOpen={modalIsOpen}
              closeModal={closeModal}
              content={instructionModalContent}
            />
            <div className="w-full my-16 md:hidden">
              <p className="text-retro-black">
                {currentQuestionNo &&
                  `${currentQuestionNo - 1} / ${
                    quizDetails?.weekId?.questions.length
                  } Answered`}
              </p>
              <div className="progress-bar-container mt-2 border border-gray-500 w-full h-4 rounded-full">
                <div
                  style={{
                    width: `${
                      ((currentQuestionNo - 1) * 100) /
                      quizDetails?.weekId?.questions.length
                    }%`,
                  }}
                  className="h-full rounded-full progress-bar-indicator"
                ></div>
              </div>
            </div>

            {loading ? (
              <div className="w-full h-screen grid place-items-center">
                <ClipLoader />
              </div>
            ) : currentQuestionNo <= quizDetails?.weekId?.questions.length ? (
              <Question
                key={quizDetails?.weekId?.questions[currentQuestionNo - 1]._id}
                question={quizDetails?.weekId?.questions[currentQuestionNo - 1]}
                handleNext={handleNext}
              />
            ) : (
              <div className="w-full h-screen flex flex-col md:justify-center items-center">
                <p className="text-xl mb-8 font-extrabold leading-loose text-center">
                  Great Job! You are one step closer to Winning Thousands in
                  Cash!<br/> <span className="">Click on Submit to Check your Score and the Leaderboard.</span>
                </p>
                <AnimatedButton
                  disabled={quizDetails?.isLoading}
                  text="Submit"
                  handleClick={handleSubmitQuiz}
                  isBigger={true}
                />
              </div>
            )}
          </section>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Quiz;
