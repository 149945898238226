import { compose, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import { rootSaga } from "./root.saga";

import { rootReducer } from "./root.reducer";

const userBlacklistedFilter = createBlacklistFilter("user", [
  "error",
  "isLoading",
]);
const quizBlacklistedFilter = createBlacklistFilter("quiz", [
  "error",
  "isLoading",
]);
const persistConfig = {
  key: "root",
  storage,
  transforms: [userBlacklistedFilter, quizBlacklistedFilter],
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWares = [
  process.env.NODE_ENV !== "production" && logger,
  sagaMiddleware,
].filter(Boolean);

const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    window &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const composedEnhancers = composeEnhancer(applyMiddleware(...middleWares));

export const store = createStore(
  persistedReducer,
  undefined,
  composedEnhancers
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
