export const Orglist = [
  {
    _id: "63d23da0879d9ef6b451b762",
    name: "Aditi Mahavidyalaya, Delhi",
  },
  {
    _id: "63d23da0879d9ef6b451b763",
    name: "AISSMS IOIT",
  },
  {
    _id: "63d23da0879d9ef6b451b764",
    name: "AKS University",
  },
  {
    _id: "63d23da0879d9ef6b451b765",
    name: "Alard College of Engineering & Management",
  },
  {
    _id: "63d23da0879d9ef6b451b766",
    name: "Amity University",
  },
  {
    _id: "63d23da0879d9ef6b451b767",
    name: "Annamacharya Institute of Technology & Sciences, Tirupati",
  },
  {
    _id: "63d23da0879d9ef6b451b768",
    name: "Arpit Baijnath Gaikwad",
  },
  {
    _id: "63d23da0879d9ef6b451b769",
    name: "Atma Ram Sanatan Dharma,  Delhi University",
  },
  {
    _id: "63d23da0879d9ef6b451b76a",
    name: "B. S. Abdur Rahman Crescent Institute of Science & Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b76b",
    name: "Bal Bharati Public School, India",
  },
  {
    _id: "63d23da0879d9ef6b451b76c",
    name: "Banaras Hindu University ",
  },
  {
    _id: "63d23da0879d9ef6b451b76d",
    name: "Bennett University",
  },
  {
    _id: "63d23da0879d9ef6b451b76e",
    name: "BJB, Utkal University",
  },
  {
    _id: "63d23da0879d9ef6b451b76f",
    name: "BV Raju Institute of Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b770",
    name: "C.V. Raman Global University",
  },
  {
    _id: "63d23da0879d9ef6b451b771",
    name: "Calcutta Institute of Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b772",
    name: "Catalyst College, Patna",
  },
  {
    _id: "63d23da0879d9ef6b451b773",
    name: "CCSU",
  },
  {
    _id: "63d23da0879d9ef6b451b774",
    name: "Ch. Charan Singh University (CCS), Meerut",
  },
  {
    _id: "63d23da0879d9ef6b451b775",
    name: "Chalapathi Institute Of Engineering And Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b776",
    name: "Chandigarh University",
  },
  {
    _id: "63d23da0879d9ef6b451b777",
    name: "Chinmaya Mission College",
  },
  {
    _id: "63d23da0879d9ef6b451b778",
    name: "Chitkara University",
  },
  {
    _id: "63d23da0879d9ef6b451b779",
    name: "CMR",
  },
  {
    _id: "63d23da0879d9ef6b451b77a",
    name: "CVR College of Engineering",
  },
  {
    _id: "63d23da0879d9ef6b451b77b",
    name: "DAVV",
  },
  {
    _id: "63d23da0879d9ef6b451b77c",
    name: "Deen Dayal Upadhyaya College",
  },
  {
    _id: "63d23da0879d9ef6b451b77d",
    name: "Delhi College of Arts and Commerce ",
  },
  {
    _id: "63d23da0879d9ef6b451b77e",
    name: "Delhi Institute Of Engineering & Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b77f",
    name: "Delhi Technological University (Formerly DCE)",
  },
  {
    _id: "63d23da0879d9ef6b451b780",
    name: "Delhi University Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b781",
    name: "Dewan Vs Institute Of Engineering And Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b782",
    name: "Doon Business School",
  },
  {
    _id: "63d23da0879d9ef6b451b783",
    name: "Dr. Akhilesh Das Gupta Institute Of Technology And Management",
  },
  {
    _id: "63d23da0879d9ef6b451b784",
    name: "Dr. B. R. Ambedkar National Institute of Technology Jalandhar",
  },
  {
    _id: "63d23da0879d9ef6b451b785",
    name: "G Pullaiah College of Engineering and Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b786",
    name: "G. H. Raisoni College of Engineering(GHRCE), Nagpur",
  },
  {
    _id: "63d23da0879d9ef6b451b787",
    name: "G. Pulla Reddy Engineering College",
  },
  {
    _id: "63d23da0879d9ef6b451b788",
    name: "Galgotias University",
  },
  {
    _id: "63d23da0879d9ef6b451b789",
    name: "Gargi College, Delhi University",
  },
  {
    _id: "63d23da0879d9ef6b451b78a",
    name: "Ghanshyam Das Saraf College",
  },
  {
    _id: "63d23da0879d9ef6b451b78b",
    name: "GLA University",
  },
  {
    _id: "63d23da0879d9ef6b451b78c",
    name: "Government College of Engineering and Leather Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b78d",
    name: "Government First Grade College for Women, Jamkhandi",
  },
  {
    _id: "63d23da0879d9ef6b451b78e",
    name: "Govt Girls College",
  },
  {
    _id: "63d23da0879d9ef6b451b78f",
    name: "Graphic Era, Deemed To Be University",
  },
  {
    _id: "63d23da0879d9ef6b451b790",
    name: "Guru Gobind Singh Indraprastha University",
  },
  {
    _id: "63d23da0879d9ef6b451b791",
    name: "Hansraj College, Delhi University",
  },
  {
    _id: "63d23da0879d9ef6b451b792",
    name: "IIHMR University ",
  },
  {
    _id: "63d23da0879d9ef6b451b793",
    name: "IIIT Sonepat - Indian Institute Of Information Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b794",
    name: "IIT BHU",
  },
  {
    _id: "63d23da0879d9ef6b451b795",
    name: "IIT Delhi",
  },
  {
    _id: "63d23da0879d9ef6b451b796",
    name: "IIT Kharagpur",
  },
  {
    _id: "63d23da0879d9ef6b451b797",
    name: "IIT Kharagpur ",
  },
  {
    _id: "63d23da0879d9ef6b451b798",
    name: "Indian Institute of Information and Technology, Lucknow",
  },
  {
    _id: "63d23da0879d9ef6b451b799",
    name: "Indira College of Commerce and Science",
  },
  {
    _id: "63d23da0879d9ef6b451b79a",
    name: "Indira Gandhi Delhi Technical University For Women (IGDTUW)",
  },
  {
    _id: "63d23da0879d9ef6b451b79b",
    name: "Indira Gandhi Engineering College Sagar",
  },
  {
    _id: "63d23da0879d9ef6b451b79c",
    name: "Institute of Engineering and Technology, DAVV, Indore",
  },
  {
    _id: "63d23da0879d9ef6b451b79d",
    name: "IPS ACADEMY, Indore",
  },
  {
    _id: "63d23da0879d9ef6b451b79e",
    name: "ISB&M School of Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b79f",
    name: "ISL Engineering College",
  },
  {
    _id: "63d23da0879d9ef6b451b7a0",
    name: "ITS Engineering College",
  },
  {
    _id: "63d23da0879d9ef6b451b7a1",
    name: "JD College of Engineering, Khandala",
  },
  {
    _id: "63d23da0879d9ef6b451b7a2",
    name: "Jyothishmathi Institute Of Technology And Sciences",
  },
  {
    _id: "63d23da0879d9ef6b451b7a3",
    name: "Kalinga University",
  },
  {
    _id: "63d23da0879d9ef6b451b7a4",
    name: "Karunya Institute of Technology and Science",
  },
  {
    _id: "63d23da0879d9ef6b451b7a5",
    name: "Kavi Kulguru Kalidas Sanskrit Vishwavidyalaya, Ramtek",
  },
  {
    _id: "63d23da0879d9ef6b451b7a6",
    name: "KL University",
  },
  {
    _id: "63d23da0879d9ef6b451b7a7",
    name: "KLE Society's College of BCA Gokak",
  },
  {
    _id: "63d23da0879d9ef6b451b7a8",
    name: "LNCT",
  },
  {
    _id: "63d23da0879d9ef6b451b7a9",
    name: "Lovely Professional University",
  },
  {
    _id: "63d23da0879d9ef6b451b7aa",
    name: "M. J. P. Rohilkhand University, Bareilly",
  },
  {
    _id: "63d23da0879d9ef6b451b7ab",
    name: "Madras Christian College ",
  },
  {
    _id: "63d23da0879d9ef6b451b7ac",
    name: "Maharaja Agrasen Institute of Technology (MAIT)",
  },
  {
    _id: "63d23da0879d9ef6b451b7ad",
    name: "Maharashtra Institute of Technology, Aurangabad",
  },
  {
    _id: "63d23da0879d9ef6b451b7ae",
    name: "Maharishi Dayanand University (MDU), Rohtak",
  },
  {
    _id: "63d23da0879d9ef6b451b7af",
    name: "Maharshi Degree College",
  },
  {
    _id: "63d23da0879d9ef6b451b7b0",
    name: "MDI, Gurgaon",
  },
  {
    _id: "63d23da0879d9ef6b451b7b1",
    name: "MES College of Engineering and Technology Kuttippuram",
  },
  {
    _id: "63d23da0879d9ef6b451b7b2",
    name: "MIT Manipal ",
  },
  {
    _id: "63d23da0879d9ef6b451b7b3",
    name: "MJPRU Bareilly",
  },
  {
    _id: "63d23da0879d9ef6b451b7b4",
    name: "MJPRU, Shahjahanpur",
  },
  {
    _id: "63d23da0879d9ef6b451b7b5",
    name: "Moran College",
  },
  {
    _id: "63d23da0879d9ef6b451b7b6",
    name: "Motilal Nehru National Institute of Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7b7",
    name: "Nalla Malla Reddy Engineering College",
  },
  {
    _id: "63d23da0879d9ef6b451b7b8",
    name: "NBKR Institute of Science and Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7b9",
    name: "New Horizon College Of Engineering",
  },
  {
    _id: "63d23da0879d9ef6b451b7ba",
    name: "NIT Jalandhar ",
  },
  {
    _id: "63d23da0879d9ef6b451b7bb",
    name: "NIT Surat",
  },
  {
    _id: "63d23da0879d9ef6b451b7bc",
    name: "Odisha University of Technology and Research, Bhubaneswar",
  },
  {
    _id: "63d23da0879d9ef6b451b7bd",
    name: "Osmania University, Hyderabad",
  },
  {
    _id: "63d23da0879d9ef6b451b7be",
    name: "P. A. College of Engineering and Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7bf",
    name: "PML SD Business School",
  },
  {
    _id: "63d23da0879d9ef6b451b7c0",
    name: "Poornima College of Engineering",
  },
  {
    _id: "63d23da0879d9ef6b451b7c1",
    name: "Presidency University",
  },
  {
    _id: "63d23da0879d9ef6b451b7c2",
    name: "Proudhadevaraya Institute Of Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7c3",
    name: "PSMO College, Tirurangadi",
  },
  {
    _id: "63d23da0879d9ef6b451b7c4",
    name: "Punjabi University, Patiala",
  },
  {
    _id: "63d23da0879d9ef6b451b7c5",
    name: "Puratchi Thalaivar Dr MGR Arts And Science College",
  },
  {
    _id: "63d23da0879d9ef6b451b7c6",
    name: "R V College of Engineering ",
  },
  {
    _id: "63d23da0879d9ef6b451b7c7",
    name: "Rajdhani College, University Of Delhi",
  },
  {
    _id: "63d23da0879d9ef6b451b7c8",
    name: "Rajiv Gandhi University Of Knowledge Technologies RKValley",
  },
  {
    _id: "63d23da0879d9ef6b451b7c9",
    name: "Rayalaseema University, Kurnool",
  },
  {
    _id: "63d23da0879d9ef6b451b7ca",
    name: "RGPV",
  },
  {
    _id: "63d23da0879d9ef6b451b7cb",
    name: "RMD Engineering College",
  },
  {
    _id: "63d23da0879d9ef6b451b7cc",
    name: "S R Engineering College",
  },
  {
    _id: "63d23da0879d9ef6b451b7cd",
    name: "Sanaka Educational Trust Group Of Institution",
  },
  {
    _id: "63d23da0879d9ef6b451b7ce",
    name: "Sant Gadge Baba Amravati University",
  },
  {
    _id: "63d23da0879d9ef6b451b7cf",
    name: "Santiniketan Polytechnic",
  },
  {
    _id: "63d23da0879d9ef6b451b7d0",
    name: "Sardar Patel Institute of Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7d1",
    name: "Sathyabama Institute of Science And Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7d2",
    name: "Shaheed Bhagat Singh College",
  },
  {
    _id: "63d23da0879d9ef6b451b7d3",
    name: "Sharada Post Graduate Institute of Research and Technological Sciences",
  },
  {
    _id: "63d23da0879d9ef6b451b7d4",
    name: "Shradhanand College",
  },
  {
    _id: "63d23da0879d9ef6b451b7d5",
    name: "Shri Balaji Institute of Technology & Management",
  },
  {
    _id: "63d23da0879d9ef6b451b7d6",
    name: "Shri Shankaracharya Technical Campus",
  },
  {
    _id: "63d23da0879d9ef6b451b7d7",
    name: "Shyam Lal College (University Of Delhi)",
  },
  {
    _id: "63d23da0879d9ef6b451b7d8",
    name: "Siddhant Institute of Computer Application, Pune",
  },
  {
    _id: "63d23da0879d9ef6b451b7d9",
    name: "SIRT",
  },
  {
    _id: "63d23da0879d9ef6b451b7da",
    name: "SR University",
  },
  {
    _id: "63d23da0879d9ef6b451b7db",
    name: "Sri Hari Degree College Kadapa",
  },
  {
    _id: "63d23da0879d9ef6b451b7dc",
    name: "Sri Vasavi Engineering College",
  },
  {
    _id: "63d23da0879d9ef6b451b7dd",
    name: "Sri Venkateswara College",
  },
  {
    _id: "63d23da0879d9ef6b451b7de",
    name: "SRM institute of science and technology ",
  },
  {
    _id: "63d23da0879d9ef6b451b7df",
    name: "SRM University",
  },
  {
    _id: "63d23da0879d9ef6b451b7e0",
    name: "SSV PG College, Hapur",
  },
  {
    _id: "63d23da0879d9ef6b451b7e1",
    name: "St Marys College",
  },
  {
    _id: "63d23da0879d9ef6b451b7e2",
    name: "St. Ann's College of Engineering and Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7e3",
    name: "St. Anne's College of Engineering and Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7e4",
    name: "Swami Shraddhanand College",
  },
  {
    _id: "63d23da0879d9ef6b451b7e5",
    name: "Swami Vivekananda Institute of Science and Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7e6",
    name: "Symbiosis International University",
  },
  {
    _id: "63d23da0879d9ef6b451b7e7",
    name: "Technical Education and Research Institute (TERI, Ghazipur)",
  },
  {
    _id: "63d23da0879d9ef6b451b7e8",
    name: "Technocrats Group of Institutes",
  },
  {
    _id: "63d23da0879d9ef6b451b7e9",
    name: "Thakur College of Engineering & Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7ea",
    name: "Thapar Institute of Engineering and Technology ",
  },
  {
    _id: "63d23da0879d9ef6b451b7eb",
    name: "The Technological Institute of Textile & Science",
  },
  {
    _id: "63d23da0879d9ef6b451b7ec",
    name: "University of Allahabad",
  },
  {
    _id: "63d23da0879d9ef6b451b7ed",
    name: "University of Delhi",
  },
  {
    _id: "63d23da0879d9ef6b451b7ee",
    name: "University of Lucknow ",
  },
  {
    _id: "63d23da0879d9ef6b451b7ef",
    name: "University of Petroleum and Energy Studies",
  },
  {
    _id: "63d23da0879d9ef6b451b7f0",
    name: "Vellore Institute of Technology",
  },
  {
    _id: "63d23da0879d9ef6b451b7f1",
    name: "VET by EHL International Professional Swiss Diploma",
  },
  {
    _id: "63d36312a6b9d6f395388abd",
    name: "S V R College of Engineering",
    createdAt: "2023-01-27T05:37:22.041Z",
    updatedAt: "2023-01-27T05:37:22.041Z",
  },
];
