import React, { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { googleSignInStart } from "../../redux/user/user.action";
import {
  selectCurrentUser,
  selectUserReducer,
} from "../../redux/user/user.selector";
import "./index.css";
const Signin = () => {
  const userReducer = useSelector(selectUserReducer);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  useEffect(() => {
    // if (userReducer?.isLoading) {
    //   setLoading(true);
    // } else {
    //   setLoading(false);
    // }
    if (currentUser?.isRegisteredComplete) {
      nav("/dashboard");
    } else if (currentUser && !currentUser?.isRegisteredComplete) {
      nav("/register");
    }
  }, [userReducer]);

  const handleGoogleSignin = (e) => {
    e.preventDefault();
    dispatch(googleSignInStart());
  };

  return (
    <div className="flex h-screen w-screen ">
      <div className="flex-1 flex justify-center items-center page">
        <div className="container text-retro-black w-full p-8 text-center">
          <h1 className="text-3xl font-bold my-4">
            Welcome to Frifty Treasure hunt
          </h1>
          <h3 className="">Sign-in to get started</h3>
          <br />
          <button
            className="border border-transparent-gray shadow-md rounded-full px-8 py-2 md:w-1/2 bg-navy-blue text-white h-12 w-4/5 flex items-center justify-center mx-auto hover:bg-sky-blue transition-all ease-in-out"
            disabled={loading}
            onClick={handleGoogleSignin}
          >
            {loading ? (
              <BeatLoader
                className=" mx-auto"
                color="#fc99f4"
                size={10}
                loading={true}
                aria-label="loading.."
              />
            ) : (
              <>
                <FcGoogle className="mr-2" />
                <div>Sign in with Google</div>
              </>
            )}
          </button>
        </div>
      </div>
      <div className="flex-1 hidden lg:flex bg-black  justify-center items-center">
        <div className="h-full w-full border-2 border-white rounded-lg text-white flex flex-col flex-wrap  content-center place-content-center">
          <br />
          <ol className="mx-auto w-2/3 ">
            <li className="text-md ">
              1. You can only register with a Google id.{" "}
            </li>
            <br />
            <li className="text-md ">
              2. We support the following browsers - Chrome, Brave, Microsoft
              Edge, Opera, Vivaldi.
            </li>
            <br />
            <li className="text-md ">
              3. Make sure that your browser is updated.
            </li>
            <br />
            <li className="text-md ">
              4. Add the Frifty extension to your{" "}
              <a
                className="text-sky-blue"
                href="https://chrome.google.com/webstore/detail/frifty/dkdenkncjfpmcifijnfghnimpgnogdnb"
                target="_"
              >
                {" "}
                browser
              </a>
              , you will need it for the next step and during the treasure hunt
            </li>
            <br />
            <li className="text-md ">
              5. If Google sign in doesn’t work, clear your browser cache and
              try again.
            </li>
            <br />
            <li className="text-md ">
              6. In case of any doubts, please reach out to your Community
              Leader. Here is the
              <a
                className="text-sky-blue"
                href="https://docs.google.com/spreadsheets/d/1wvW_JsBJMoh-I14dVHshPjMNObNy6DQCsPC2lXzBwtU/edit#gid=0"
                target="_"
              >
                {" "}
                list
              </a>
              .
            </li>
            <br />
            <li className="text-md">
              7. If the doubts persist, please drop a mail to{" "}
              <a href="mailto:hello@friftysearch.com">
                hello@friftysearch.com.
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Signin;
