import { createAction } from "../../utils/reducer/reducer.util"
import { LEADERBOARD_ACTION_TYPES } from "./leaderboard.action.types";

export const fetchLeaderboardStart = () => {
    return createAction(LEADERBOARD_ACTION_TYPES.FETCH_LEADERBOARD_START);
}

export const fetchLeaderboardSuccess = (payload) => {
    return createAction(LEADERBOARD_ACTION_TYPES.FETCH_LEADERBOARD_SUCCESS, payload);
}

export const fetchLeaderboardFailure = (error) => {
    return createAction(LEADERBOARD_ACTION_TYPES.FETCH_LEADERBOARD_FAILURE, error);
}

