import React, { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Example from "../../Assets/images/example.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { registerUserStart } from "../../redux/user/user.action";
import {
  selectCurrentUser,
  selectOrgList,
  selectUserReducer,
} from "../../redux/user/user.selector";
import "./index.css";
import CustomModal from "../../components/customModal";
import { Orglist } from "./org";
import { toast, ToastContainer } from "react-toastify";

const Register = () => {
  const userReducer = useSelector(selectUserReducer);
  const currentUser = useSelector(selectCurrentUser);
  const orgList = useSelector(selectOrgList) ?? Orglist;
  const [loading, setLoading] = useState(false);
  const [friftyId, setFriftyId] = useState("");
  const [otherSelected, setOtherSelected] = useState(true);
  const [org, setOrg] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [name, setName] = useState(currentUser?.userName ?? "");
  const dispatch = useDispatch();
  const nav = useNavigate();

  if (!currentUser) {
    window.location.href = "/sign-in";
  }

  useEffect(() => {
    if (!userReducer || !currentUser) nav("/sign-in");
    if (userReducer?.isLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    console.log(currentUser);
    if (currentUser.isRegisteredComplete) {
      nav("/dashboard");
    }
  }, [userReducer]);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!name || !friftyId || !org) {
      toast.error(`Please fill all fields`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    dispatch(registerUserStart({ friftyId, name, org }));
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  const registerModalContent = (
    <div className="flex-1  bg-black p-4 ">
      <button className="block fit-content ml-auto text-white">
        <AiOutlineCloseCircle
          onClick={closeModal}
          className="mb-2 cursor-pointer"
          size={30}
        />
      </button>
      <div className="h-full w-full text-white mb-4">
        <div className="text-3xl font-bold my-4 text-center ">
          How to generate your Frifty id?{" "}
        </div>
        <br />
        <ol className="mx-auto text-sm  w-3/4">
          <li className=" ">
            1. Make sure you are using Chrome/Brave/Opera as your browser.
          </li>
          <br />
          <li className=" ">
            2. Add the Frifty extension to your browser -
            <a
              className="text-sky-blue"
              href="https://chrome.google.com/webstore/detail/frifty/dkdenkncjfpmcifijnfghnimpgnogdnb"
              target="_"
            >
              Click Here
            </a>
            .
          </li>
          <br />
          <li className="">
            3. Search for "my frifty id" on the URL bar or Google or
            <a
              className="text-sky-blue"
              href="https://www.google.com/search?q=my+frifty+id"
              target="_"
            >
              Click here
            </a>
            .
          </li>
          <br />
          <img src={Example} alt="frifty id example" />
          <br />
          <li className=" ">
            4. If you haven't been able to generate your id yet, drop us an
            email at hello@friftysearch.com with the subject -"Not able to get
            frifty id"
          </li>
        </ol>
      </div>
    </div>
  );

  return (
    <div className="flex h-screen w-screen ">
      <div className="flex-1 relative flex flex-col justify-center items-center page">
        <div className=" text-retro-black w-full  p-8">
          <h1 className="text-3xl font-bold my-4 text-center ">
            Complete Registration <br />{" "}
            <span className="text-fun-orange">to get started</span>
          </h1>
          {/* <p className="text-sm text-center">Register to play the quiz</p> */}
          <br />
          <div className="md:w-1/2 mx-auto">
            <label className="text-md text-left font-bold">Name</label>
            <input
              value={name ?? ""}
              onChange={(e) => setName(e.target.value)}
              className="py-1 bg-transparent text-sm w-full focus:outline-none border-b border-b-retro-black"
            />
          </div>
          <div className="mt-4 mb-8 md:w-1/2 mx-auto">
            <label className="text-md text-left font-bold">Frifty Id</label>
            <input
              value={friftyId ?? ""}
              onChange={(e) => setFriftyId(e.target.value)}
              className="py-1 bg-transparent text-sm w-full focus:outline-none border-b border-b-retro-black"
            />
          </div>
          <div className="mt-4 mb-8 md:w-1/2 mx-auto">
            <label className="text-md text-left font-bold">
              College/Organization
            </label>
            <select
              className=" py-1 text-sm bg-transparent  w-full mb-8 focus:outline-none border-b border-b-retro-black"
              value={org ?? ""}
              onChange={(e) => {
                setOrg(e.target.value);
                if (e.target.value) setOtherSelected(false);
                else setOtherSelected(true);
              }}
            >
              <option className="text-xs" disabled>
                Select your College/Organization{" "}
              </option>
              <option
                className="text-xs"
                selected="selected"
                value=""
                key={"other"}
              >
                Other
              </option>
              {orgList.map((item) => (
                <option className="text-xs" value={item.name} key={item._id}>
                  {item.name}
                </option>
              ))}
            </select>
            {otherSelected && (
              <>
                <label className="text-md text-left font-bold">
                  If Other, Please Specify
                </label>
                <input
                  value={org ?? ""}
                  onChange={(e) => setOrg(e.target.value)}
                  className="py-1 bg-transparent text-lg w-full focus:outline-none border-b border-b-retro-black"
                />
              </>
            )}
            {/* <input
              value={org ?? ""}
              onChange={(e) => setOrg(e.target.value)}
              className="py-1 bg-transparent text-lg w-full focus:outline-none border-b border-b-retro-black"
            /> */}
          </div>
          <button
            className="border border-transparent-gray shadow-md rounded-full px-8 py-2 bg-navy-blue text-white h-12 w-4/5 flex items-center justify-center mx-auto hover:bg-sky-blue transition-all ease-in-out md:w-1/2"
            disabled={loading}
            onClick={handleRegister}
          >
            {loading ? (
              <BeatLoader
                className=" mx-auto"
                color="#fc99f4"
                size={10}
                loading={true}
                aria-label="loading.."
              />
            ) : (
              <>
                <div>Register</div>
              </>
            )}
          </button>
        </div>
        <button
          className="lg:hidden absolute right-4 bottom-4 py-2 px-6 bg-cool-blue hover:bg-cool-green text-white rounded-full hover floating "
          onClick={openModal}
        >
          Need help regarding frifty Id
        </button>
      </div>
      <CustomModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={registerModalContent}
      />
      <div className="flex-1 hidden lg:block bg-black p-4 flex justify-center items-center">
        <div className="h-full w-full border-2 border-white rounded-lg text-white flex flex-col flex-wrap  content-center place-content-center">
          <h1 className="text-3xl font-bold my-4 text-center ">
            How to generate your Frifty id?{" "}
          </h1>
          <br />
          <ol className="mx-auto  w-2/3">
            <li className="text-md">
              1. Make sure you are using Chrome/Brave/Opera as your browser
              <a
                className="text-sky-blue"
                href="https://chrome.google.com/webstore/detail/frifty/dkdenkncjfpmcifijnfghnimpgnogdnb"
                target="_"
              >
                {" "}
                here
              </a>
            </li>
            <br />
            <li className="text-md ">
              2. Add the Frifty extension to your browser -
              <a
                className="text-sky-blue"
                href="https://chrome.google.com/webstore/detail/frifty/dkdenkncjfpmcifijnfghnimpgnogdnb"
                target="_"
              >
                {" "}
                Click Here
              </a>
              .
            </li>
            <br />
            <img src={Example} alt="frifty id example" />
            <br />
            <li className="text-md ">
              3. Search for "my frifty id" on the URL bar or Google or -
              <a
                className="text-sky-blue"
                href="https://www.google.com/search?q=my+frifty+id"
                target="_"
              >
                {" "}
                Click here
              </a>
              .
            </li>
            <br />
            <li className="text-md ">
              4. If you haven't been able to generate your id yet, drop us an
              email at hello@friftysearch.com with the subject -"Not able to get
              frifty id"
            </li>
          </ol>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
