import React from "react";

import Modal from "react-modal";
const CustomModal = ({ closeModal, modalIsOpen, content }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      width: "90%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      borderRadius: "20px",
    },
  };
  return (
    <div className="lg:hidden">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        {content}
      </Modal>
    </div>
  );
};

export default CustomModal;
