import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../redux/quiz/quiz.selector";

const Question = ({ question,  handleNext }) => {
  const { questionName, choices: options } = question;
  const [clicked, setClicked] = useState('');
  const isLoading = useSelector(selectIsLoading);

  // const handleSubmit = (option) => {
  //   handleResponse({ _id: question._id, answer: option, endTime: Date.now() });
  // };

  useEffect(()=>{
    setClicked('');
  },[])

  return (
    <div className="flex flex-col justify-center">
      <h1 className="text-2xl lg:text-4xl leading-loose font-black mb-12 ">
        {questionName}
      </h1>
      <div className="options w-full grid lg:grid-cols-2 grid-rows-2 gap-2  lg:gap-8">
        {
        options.length > 0 ? options.map((option, index) => {
          return (
            <div
              key={index}
              className={`text-lg lg:text-xl flex w-full p-2 pl-6 border-2   rounded-full hover:border-retro-black ${
                clicked == option ? "border-retro-black" : "border-gray-400"
              } transition-all ease-in-out`}
              onClick={() => setClicked(option)}
            >
              <span className="flex-1">{option}</span>
              <div
                className={`w-4 h-4 lg:w-8 lg:h-8 border-2 border-gray-400 rounded-full ${
                  clicked == option && "bg-fun-orange"
                }`}
              ></div>
            </div>
          );
        }) : 
        <input placeholder="Type your answer here.." className="w-full text-2xl mt-4 border-b-2 border-retro-black focus:outline-none bg-transparent" value={clicked} onChange={(e)=>{
          setClicked(e.target.value);
        }}></input>}
      </div>
      <div className="mt-16 w-full flex justfy-center">
      
        <button
          className={ `px-8 py-4 border border-white hover:opacity-75 disabled:text-white ml-auto cursor-pointer  disabled:opacity-25   bg-sky-blue rounded-lg text-white`}
          onClick={() => {
            if(!clicked){
              alert("Answer field can't be empty");
              return;
            }
            handleNext({
              questionId:question._id,
              answer: clicked ,
              endTime: Date.now(),
            })
            setClicked('')
          } }
          disabled={isLoading}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Question;
