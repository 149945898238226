import  { useEffect } from "react"
import { getCurrentUserToken, signInWithGooglePopup, signOutUser } from "./utils/firebase/firebase.util";
import { Routes , Route, Navigate } from "react-router-dom"
import Signin from "./pages/signin";
import Dashboard from "./pages/dashboard";
import Leaderboard from "./pages/leaderboard";
import Home from "./pages/Home";
import Quiz from "./pages/quiz";
import Register from "./pages/register";
import 'react-toastify/dist/ReactToastify.css';
import Rules from "./pages/rules";
import HowToPlay from "./pages/howToPlay";
import Faqs from "./pages/faqs";
import Prizes from "./pages/prizes";
import Help from "./pages/help";

function App() {
  

  const googleSignIn = async () => {
    const user = await signInWithGooglePopup();
  }

  const showToken = async () => {
    const token = await getCurrentUserToken();
  }

  const doLogout = async () => {
    await signOutUser();
  }

  return (
    <div className="App h-screen w-screen">
      <Routes>
        <Route path="/" element={<Navigate to="/sign-in" />} />
        <Route path="/sign-in" element={<Signin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Navigate to="home"/>} />
          <Route path="home" element={<Home/>}/> 
          <Route path="leaderboard" element={<Leaderboard />} />
          <Route path="rules" element={<Rules />} />
          <Route path="how-to-play" element={<HowToPlay />} />
          <Route path="prizes" element={<Prizes/>}/>
          <Route path="faqs" element={<Faqs/>}/>
          <Route path="help" element={<Help/>}/>
        </Route>
        <Route path="/quiz" element={<Quiz />} />   
      </Routes> 
      
    </div>
  );
}

export default App;
