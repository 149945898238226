import { combineReducers } from "redux";
import { leaderboardReducer } from "./leaderboard/leaderboard.reducer";
import { quizReducer } from "./quiz/quiz.reducer";
import { userReducer } from "./user/user.reducer";

export const rootReducer = combineReducers({
    user:userReducer,
    quiz:quizReducer,
    leaderboard:leaderboardReducer
});
