import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../components/accordion";
import { fetchLeaderboardStart } from "../../redux/leaderboard/leaderboard.action";
import { selectLeaderboard } from "../../redux/leaderboard/leaderboard.selector";
import { IoMdRefresh } from "react-icons/io"
import "./index.css"
const Leaderboard = () => {
  const dispatch = useDispatch();
  const leaderboard = useSelector(selectLeaderboard);

  useEffect(() => {
    if (!leaderboard) dispatch(fetchLeaderboardStart());
  }, []);

  return (
    <div className="px-4 md:p-0 flex flex-col items-center md:justify-center h-screen w-full oveflow-y-hidden">
      <div className="w-full md:w-1/2 overflow-y-hidden">
        <h1 className="text-2xl font-extrabold my-8 text-center px-4">
          Leaderboard
        </h1>
        <aside className="flex items-center justify-center text-sm text-retro-gray text-center mb-8 px-4">
          <div className="flex-1">Top 10 users of this week till now</div>
          <button className=" bg-sky-blue px-4 py-1 rounded-full hover:opacity-50 active:opacity-100" onClick={()=>dispatch(fetchLeaderboardStart())}><IoMdRefresh size={20} color="#fff"/></button>
        </aside>
        <section className="leaderboard-table h-3/4 overflow-y-auto px-4">
          {leaderboard?.map((user, index) => (
            <Accordion key={index} user={user} />
          ))}
        </section>
      </div>
    </div>
  );
};

export default Leaderboard;
