import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { MdLeaderboard, MdOutlineLogout, MdOutlineInfo } from "react-icons/md";
import { GiTargetPrize } from "react-icons/gi";
import { FaQuestionCircle, FaHandsHelping } from "react-icons/fa";
import { IoMdListBox } from "react-icons/io";
import { signOutStart } from "../../redux/user/user.action";
import { selectCurrentUser } from "../../redux/user/user.selector";
const Sidebar = ({ openSidebar, toggleSidebar }) => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const location = useLocation().pathname.split("/")[2];

  useEffect(() => {
    if (!currentUser) {
      nav("/sign-in");
    }
    return () => {
      console.log("Unmounted Sidebar");
    };
  }, [currentUser]);

  const handleSignout = () => {
    dispatch(signOutStart());
  };
  const items = [
    {
      name: "Home",
      path: "home",
      icon: <AiFillHome className="mr-4" />,
    },
    {
      name: "Leaderboard",
      path: "leaderboard",
      icon: <MdLeaderboard className="mr-4" />,
    },
    {
      name: "How to Play",
      path: "how-to-play",
      icon: <MdOutlineInfo className="mr-4" />,
    },
    {
      name: "Prizes",
      path: "prizes",
      icon: <GiTargetPrize className="mr-4" />,
    },
    {
      name: "FAQs",
      path: "faqs",
      icon: <FaQuestionCircle className="mr-4" />,
    },
    {
      name: "Need help?",
      path: "help",
      icon: <FaHandsHelping className="mr-4" />,
    },
  ];
  return (
    <div
      className={`${
        !openSidebar ? "hidden w-full fixed " : "block w-full fixed"
      } md:block md:w-1/5 md:sticky z-20  p-8 min-h-screen sidebar bg-retro-black `}
    >
      <div className="flex flex-col text-left text-white font-bold h-full">
        <div className="md:block hidden text-xl py-8">
          <span className="text-fun-pink">Frifty</span> Treasure Hunt
        </div>
        <div className="py-8">
          <ul>
            {items.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`py-4 flex items-center ${
                    location === item.path && "text-fun-orange"
                  }`}
                >
                  {item.icon}
                  <Link to={item.path} onClick={toggleSidebar}>
                    {item.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex-1"></div>
        <div
          className="py-4 flex items-center active:text-rose-500 cursor-pointer"
          onClick={handleSignout}
        >
          <MdOutlineLogout className="mr-4" />
          Logout
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
