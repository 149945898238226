import React from 'react'
import {GiHamburgerMenu} from "react-icons/gi"
const Navbar = ({toggleSidebar}) => {
  return (
    <nav className="mlock md:hidden flex items-center px-8 py-4 bg-retro-black text-white sticky z-50 top-0 mb-2 w-full">
        <span className="text-lg flex-1"><span className="text-fun-orange">Frifty</span> Treasurehunt</span>
        <GiHamburgerMenu size={20} onClick={toggleSidebar}/>
    </nav>
  )
}

export default Navbar