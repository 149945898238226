import { takeLatest, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { USER_ACTION_TYPES } from "./user.action.types";

import {
  signInSuccess,
  signInFailed,
  signOutSuccess,
  signOutFailed,
  registerUserSuccess,
  registerUserFailure,
  fetchCurrentUserScoreSuccess,
  fetchCurrentUserScoreFailure,
} from "./user.action";

import {
  fetchCurrentUserScoreService,
  loginUser,
  registerUserService,
} from "../../utils/api/api.utils";
import {
  getCurrentUser,
  getCurrentUserToken,
  signInWithGooglePopup,
  signOutUser,
} from "../../utils/firebase/firebase.util";

export function* getUserInfoFromAPI(userAuth) {
  try {
    const idToken = yield call(getCurrentUserToken);
    const userSnapshot = yield call(loginUser, {
      idToken: idToken,
    });

    yield put(signInSuccess({ ...userSnapshot }));
    toast.success("Successfully signed in", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (error) {
    // if (
    //   error.message.includes("Request failed with status code 401") ||
    //   error.message.includes("Request failed with status code 404")
    // ) {
    //   const idToken = yield call(getCurrentUserToken);
    //   const userSnapshot = yield call(createUser, { idToken });
    //   yield put(signUpSuccess({ ...userSnapshot}));
    //   toast.success('Successfully Signed Up',{
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // } else {

    toast.error(error.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(signInFailed(error));
    // }
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield call(signInWithGooglePopup);
    yield call(getUserInfoFromAPI, user);
  } catch (error) {
    yield put(signInFailed(error));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield call(getCurrentUser);
    if (!userAuth) return;

    yield call(getUserInfoFromAPI, userAuth);
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* signOut() {
  try {
    yield call(signOutUser);
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailed(error));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

export function* registerUser({ payload }) {
  try {
    const idToken = yield call(getCurrentUserToken);
    const registeredUser = yield call(registerUserService, {
      idToken,
      payload,
    });
    yield put(registerUserSuccess(registeredUser));
    toast.success("Successfully Registered Skills User", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (error) {
    yield put(registerUserFailure(error));
    toast.error(error.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

export function* fetchCurrentUserScore() {
  try {
    const idToken = yield call(getCurrentUserToken);
    const score = yield call(fetchCurrentUserScoreService, { idToken });
    console.log(score);
    yield put(fetchCurrentUserScoreSuccess(score));
  } catch (error) {
    yield put(fetchCurrentUserScoreFailure(error));
    toast.error("Error updating score", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

export function* registerUserOnStart() {
  yield takeLatest(USER_ACTION_TYPES.REGISTER_USER_START, registerUser);
}

export function* onGoogleSignInStart() {
  yield takeLatest(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onCheckUserSession() {
  yield takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOut);
}

export function* fetchCurrentUserScoreOnStart() {
  yield takeLatest(
    USER_ACTION_TYPES.FETCH_CURRENT_USER_SCORE_START,
    fetchCurrentUserScore
  );
}

export function* userSagas() {
  yield all([
    call(onCheckUserSession),
    call(onGoogleSignInStart),
    call(onSignOutStart),
    call(registerUserOnStart),
    call(fetchCurrentUserScoreOnStart),
  ]);
}
