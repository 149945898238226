import { takeLatest, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  checkAnswerService,
  fetchCurrentSubmissionService,
  playQuizService,
  submitQuizService,
} from "../../utils/api/api.utils";
import { getCurrentUserToken } from "../../utils/firebase/firebase.util";
import {
  fetchCurrentSubmissionFailure,
  fetchCurrentSubmissionSuccess,
  playQuizFailure,
  playQuizSuccess,
  submitQuizFailure,
  submitQuizSuccess,
  checkAnswerSuccess,
  checkAnswerFailure
} from "./quiz.action";

import { fetchLeaderboardStart } from "../leaderboard/leaderboard.action";
import { QUIZ_ACTION_TYPES } from "./quiz.action.types";
import { fetchCurrentUserScoreStart } from "../user/user.action";

export function* fetchCurrentSubmission() {
  try {
    const idToken = yield call(getCurrentUserToken);
    const currentSubmission = yield call(fetchCurrentSubmissionService, {
      idToken: idToken,
    });
    // throw new Error("Some error")
    yield put(fetchCurrentSubmissionSuccess(currentSubmission));
  } catch (error) {
    yield put(fetchCurrentSubmissionFailure(error));
  }
}

export function* playQuiz() {
  try {
    const idToken = yield call(getCurrentUserToken);
    const quizDetails = yield call(playQuizService, {
      idToken: idToken,
    });
    yield put(playQuizSuccess({ ...quizDetails }));
  } catch (error) {
    yield put(playQuizFailure(error));
  }
}

export function* submitQuiz({ payload }) {
  try {
    const idToken = yield call(getCurrentUserToken);
    const response = yield call(submitQuizService, {
      idToken: idToken,
      payload,
    });
    yield call(fetchCurrentSubmission); // fetches the current Submission and updates the reducer
    yield put(submitQuizSuccess());
  } catch (error) {
    yield put(submitQuizFailure(error));
  }
}


export function* checkAnswer({ payload }) {
  try {
    const idToken = yield call(getCurrentUserToken);
    const response = yield call(checkAnswerService, {
      idToken: idToken,
      payload,
    });
    yield put(checkAnswerSuccess({...response,startTime:Date.now()}));
  } catch (error) {
    toast.error(error.message,{
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    yield put(checkAnswerFailure(error))
  }
}

export function* fetchCurrentSubmissionOnStart() {
  yield takeLatest(
    QUIZ_ACTION_TYPES.FETCH_CURRENT_SUBMISSION_START,
    fetchCurrentSubmission
  );
}

export function* playQuizOnStart() {
  yield takeLatest(QUIZ_ACTION_TYPES.PLAY_QUIZ_START, playQuiz);
}


export function* submitQuizOnStart() {
  yield takeLatest(QUIZ_ACTION_TYPES.SUBMIT_QUIZ_START, submitQuiz);
}

export function* checkAnswerOnStart() {
  yield takeLatest(QUIZ_ACTION_TYPES.CHECK_ANSWER_START, checkAnswer);
}

export function* quizSagas() {
  yield all([
    call(fetchCurrentSubmissionOnStart),
    call(playQuizOnStart),
    call(submitQuizOnStart),
    call(checkAnswerOnStart),
  ]);
}
