import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import AnimatedButton from "../../components/animatedButton";
import { fetchCurrentSubmissionStart } from "../../redux/quiz/quiz.action";
import moment from "moment";
import {
  selectCurrentSubmission,
  selectCurrentWeek,
  selectQuizStoreIsLoading,
  selectWeeks,
} from "../../redux/quiz/quiz.selector";
import { selectCurrentUserscore } from "../../redux/user/user.selector";
import { ToastContainer } from "react-toastify";
import { fetchCurrentUserScoreStart } from "../../redux/user/user.action";

const Home = () => {
  const currentSubmission = useSelector(selectCurrentSubmission);
  const currentScore = useSelector(selectCurrentUserscore);
  const weeksInfo = useSelector(selectWeeks);
  const currentWeek = useSelector(selectCurrentWeek);
  const loading = useSelector(selectQuizStoreIsLoading);
  const dispatch = useDispatch();
  const nav = useNavigate();
  useEffect(() => {
    dispatch(fetchCurrentUserScoreStart());
    if (!currentSubmission) dispatch(fetchCurrentSubmissionStart());
  }, []);
  const handlePlayQuiz = async () => {
    nav("/quiz", { state: { eligible: true } });
  };

  return loading ? (
    <div className="px-4 md:p-0 flex flex-col items-center md:justify-center h-screen w-full bg-transparent-gray ">
      <ClipLoader />
    </div>
  ) : (
    <section className="px-4 pt-4 md:px-0 md:pt-16 flex flex-col  items-center h-screen w-full ">
      <div className="md:w-2/3 w-full text-md md:text-xl text-sky-blue md:mb-20 mb-8 flex items-center justify-end">
        <div className="ml-4 text-3xl text-fun-pink bg-white px-4 py-2 border border-2 border-retro-black">
          Total Score -{" "}
          <div className="inline font-bold text-retro-black">
            {" "}
            {currentScore != null ? (
              currentScore.toFixed(2)
            ) : (
              <ClipLoader size={20} />
            )}{" "}
          </div>{" "}
        </div>{" "}
      </div>
      <div className="md:w-2/3 w-full text-md md:text-xl text-retro-black">
        Welcome to the Frifty Treasure Hunt. In the meantime, check out our{" "}
        <a
          className="italic text-sky-blue"
          href="https://blog.friftysearch.com"
          target="_blank"
          rel="noreferrer"
        >
          website
        </a>{" "}
        and{" "}
        <a
          className="italic text-sky-blue"
          href="https://blog.friftysearch.com"
          target="_blank"
          rel="noreferrer"
        >
          blog
        </a>{" "}
        to familiarise yourself with Frifty’s uses - these will come in handy.{" "}
        <br />
      </div>
      <br />
      <table className="md:w-2/3 w-full leading-normal border-retro-black border-4">
        <thead>
          <tr>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-retro-black text-left text-xs font-semibold text-white uppercase tracking-wider">
              Week No
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-retro-black text-left text-xs font-semibold text-white uppercase tracking-wider">
              Start Date
            </th>

            <th className="px-5 py-3 border-b-2 border-gray-200 bg-retro-black text-left text-xs font-semibold text-white uppercase tracking-wider">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {weeksInfo.map(({ weekNo, startTime }, index) => (
            <tr className="" key={index}>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-1/5 md:w-fit">
                <div>
                  <div className="ml-3">
                    <p className="text-gray-600 ">
                      {weekNo === 4 ? "Finale" : `Qualifier - ${weekNo}`}
                    </p>
                  </div>
                </div>
              </td>
              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm w-2/5 md:w-fit">
                <p className="text-gray-600 ">
                  {moment(startTime).format("Do MMM, YYYY")}
                </p>
              </td>
              <td className="px-2 py-5 border-b border-gray-200 bg-white text-sm whitespace-no-wrap w-2/5 md:w-fit">
                <div className="text-gray-900 whitespace-no-wrap">
                  {weekNo < currentWeek ? (
                    "Closed"
                  ) : weekNo === currentWeek ? (
                    currentSubmission?.isSubmitted ? (
                      <span className="text-white rounded-lg bg-green-600 px-2 py-1">
                        Submitted
                      </span>
                    ) : JSON.stringify(currentSubmission) !== "{}" ? (
                      <AnimatedButton
                        text="Resume Quiz"
                        handleClick={handlePlayQuiz}
                      />
                    ) : (
                      <AnimatedButton
                        text="Enter Treasurehunt"
                        handleClick={handlePlayQuiz}
                        isBigger={false}
                      />
                    )
                  ) : (
                    <span className="text-white rounded-lg bg-gray-600 px-2 py-1">
                      Not Started
                    </span>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </section>
  );
};

export default Home;
