import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar";
import { Outlet } from "react-router-dom";
import "./index.css";
import Navbar from "../../components/navbar";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selector";
const Dashboard = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const nav = useNavigate();

  if (!currentUser) {
    window.location.href = "/sign-in";
  } else if (currentUser && !currentUser?.isRegisteredComplete) {
    window.location.href = "/register";
  }

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <div className="flex md:flex-row flex-col">
      <Sidebar openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <Navbar toggleSidebar={toggleSidebar} />
      <main className="outlet h-screen flex-1 w-full md:w-4/5 overflow-y-auto">
        <Outlet />
      </main>
    </div>
  );
};

export default Dashboard;
