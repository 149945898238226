export const QUIZ_ACTION_TYPES = {
  FETCH_CURRENT_SUBMISSION_START: "quiz/FETCH_CURRENT_SUBMISSION_START",
  FETCH_CURRENT_SUBMISSION_SUCCESS: "quiz/FETCH_CURRENT_SUBMISSION_SUCCESS",
  FETCH_CURRENT_SUBMISSION_FAILURE: "quiz/FETCH_CURRENT_SUBMISSION_FAILURE",
  PLAY_QUIZ_START: "quiz/PLAY_QUIZ_START",
  PLAY_QUIZ_SUCCESS: "quiz/PLAY_QUIZ_SUCCESS",
  PLAY_QUIZ_FAILURE: "quiz/PLAY_QUIZ_FAILURE",
  CHECK_ANSWER_START : "quiz/CHECK_ANSWER_START",
  CHECK_ANSWER_SUCCESS : "quiz/CHECK_ANSWER_SUCCESS",
  CHECK_ANSWER_FAILURE : "quiz/CHECK_ANSWER_FAILURE",
  SUBMIT_QUIZ_START: "quiz/SUBMIT_QUIZ_START",
  SUBMIT_QUIZ_SUCCESS: "quiz/SUBMIT_QUIZ_SUCCESS",
  SUBMIT_QUIZ_FAILURE: "quiz/SUBMIT_QUIZ_FAILURE",
};
