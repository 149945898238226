export const USER_ACTION_TYPES = {
    SET_CURRENT_USER: "user/SET_CURRENT_USER",
    CHECK_USER_SESSION: "user/CHECK_USER_SESSION",
    GOOGLE_SIGN_IN_START: "user/GOOGLE_SIGN_IN_START",
    SIGN_IN_SUCCESS: "user/SIGN_IN_SUCCESS",
    SIGN_IN_FAILED: "user/SIGN_IN_FAILED",
    SIGN_OUT_START: "user/SIGN_OUT_START",
    SIGN_OUT_SUCCESS: "user/SIGN_OUT_SUCCESS",
    SIGN_OUT_FAILED: "user/SIGN_OUT_FAILED",
    REGISTER_USER_START:"user/REGISTER_USER_START",
    REGISTER_USER_SUCCESS:"user/REGISTER_USER_SUCCESS",
    REGISTER_USER_FAILURE:"user/REGISTER_USER_FAILURE",
    FETCH_CURRENT_USER_SCORE_START:"user/FETCH_CURRENT_USER_SCORE_START",
    FETCH_CURRENT_USER_SCORE_SUCCESS:"user/FETCH_CURRENT_USER_SCORE_SUCCESS",
    FETCH_CURRENT_USER_SCORE_FAILURE:"user/FETCH_CURRENT_USER_SCORE_FAILURE"
  };
  