import { USER_ACTION_TYPES } from "../user/user.action.types";
import { LEADERBOARD_ACTION_TYPES } from "./leaderboard.action.types";

const INITIAL_STATE = {
  leaderboard: null,
  isLoading: false,
  error: null,
};

export const leaderboardReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LEADERBOARD_ACTION_TYPES.FETCH_LEADERBOARD_START:
      return { ...state, isLoading: true, error: null };
    case LEADERBOARD_ACTION_TYPES.FETCH_LEADERBOARD_SUCCESS:
      return { ...state, isLoading: false, error: null, leaderboard: payload };
    case LEADERBOARD_ACTION_TYPES.FETCH_LEADERBOARD_FAILURE:
      return { ...state, isLoading: false, error: payload.message };
    case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
