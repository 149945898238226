import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

console.log(process.env.REACT_APP_NODE_ENV);

const firebaseConfig =
  process.env.REACT_APP_NODE_ENV === "development"
    ? {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "treasurehunt-f5dfd.firebaseapp.com",
        projectId: "treasurehunt-f5dfd",
        storageBucket: "treasurehunt-f5dfd.appspot.com",
        messagingSenderId: "439172545341",
        appId: "1:439172545341:web:f2bd6de3a52194213d6354",
      }
    : {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: "frifty-treasurehunt.firebaseapp.com",
        projectId: "frifty-treasurehunt",
        storageBucket: "frifty-treasurehunt.appspot.com",
        messagingSenderId: "733983201749",
        appId: "1:733983201749:web:d8c4e7ed2a9f1ff69a455c",
        measurementId: "G-SM089XB6Q5",
      };

const app = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth();
export const signInWithGooglePopup = async () => {
  return await signInWithPopup(auth, googleProvider);
};
export const signInWithGoogleRedirect = async () => {
  return await signInWithRedirect(auth, googleProvider);
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};

export const getCurrentUserToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        userAuth.getIdToken().then(resolve).catch(reject);
      },
      reject
    );
  });
};
