import { QUIZ_ACTION_TYPES } from "./quiz.action.types";
import { createAction } from "../../utils/reducer/reducer.util";

export const fetchCurrentSubmissionStart = () => {
  return createAction(QUIZ_ACTION_TYPES.FETCH_CURRENT_SUBMISSION_START);
};

export const fetchCurrentSubmissionSuccess = (payload) => {
  return createAction(
    QUIZ_ACTION_TYPES.FETCH_CURRENT_SUBMISSION_SUCCESS,
    payload
  );
};

export const fetchCurrentSubmissionFailure = (error) => {
  return createAction(
    QUIZ_ACTION_TYPES.FETCH_CURRENT_SUBMISSION_FAILURE,
    error
  );
};

export const playQuizStart = () => {
  return createAction(QUIZ_ACTION_TYPES.PLAY_QUIZ_START);
};

export const playQuizSuccess = (payload) => {
  return createAction(QUIZ_ACTION_TYPES.PLAY_QUIZ_SUCCESS, payload);
};

export const playQuizFailure = (error) => {
  return createAction(QUIZ_ACTION_TYPES.PLAY_QUIZ_FAILURE, error);
};

export const submitQuizStart = (response) => {
  return createAction(QUIZ_ACTION_TYPES.SUBMIT_QUIZ_START, response);
};

export const submitQuizSuccess = () => {
  return createAction(QUIZ_ACTION_TYPES.SUBMIT_QUIZ_SUCCESS,{});
};

export const submitQuizFailure = (error) => {
  return createAction(QUIZ_ACTION_TYPES.SUBMIT_QUIZ_FAILURE, error);
};

export const checkAnswerStart = (data) => {
  return createAction(QUIZ_ACTION_TYPES.CHECK_ANSWER_START, data);
}

export const checkAnswerSuccess = (payload) => {
  return createAction(QUIZ_ACTION_TYPES.CHECK_ANSWER_SUCCESS, payload)
}

export const checkAnswerFailure = (error) => {
  return createAction(QUIZ_ACTION_TYPES.CHECK_ANSWER_FAILURE, error)
}
