import { USER_ACTION_TYPES } from "../user/user.action.types";
import { QUIZ_ACTION_TYPES } from "./quiz.action.types";

const INITIAL_STATE = {
  quizDetails: null,
  currentSubmission: null,
  weeks: [],
  currentWeek:null,
  startTime:null,
  response:[],
  currentQuestionNo:1,
  isLoading: false,
  error: null,
};

export const quizReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case QUIZ_ACTION_TYPES.FETCH_CURRENT_SUBMISSION_START:
      return { ...state, isLoading: true, error: null };
    case QUIZ_ACTION_TYPES.FETCH_CURRENT_SUBMISSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        currentSubmission: { ...state.currentSubmission, ...payload.submission },
        weeks: [...payload.weeks],
        currentQuestionNo:payload.submission ? payload?.submission?.answers?.length + 1 : 1,
        currentWeek:payload.currentWeek,
      };
    case QUIZ_ACTION_TYPES.FETCH_CURRENT_SUBMISSION_FAILURE:
      return { ...state, isLoading: false, error: payload.message };
    case QUIZ_ACTION_TYPES.PLAY_QUIZ_START:
      return { ...state, isLoading: true, error: null };
    case QUIZ_ACTION_TYPES.PLAY_QUIZ_SUCCESS:
      return {
        ...state,
        startTime:'updatedAt' in payload ? new Date(payload.updatedAt).getTime() : new Date(payload.startTime).getTime(),
        isLoading: false,
        error: null,
        quizDetails: { ...state.quizDetails, ...payload },
      };
    case QUIZ_ACTION_TYPES.PLAY_QUIZ_FAILURE:
      return { ...state, isLoading: false, error: payload.message };
    case QUIZ_ACTION_TYPES.CHECK_ANSWER_START:
      return { ...state,isLoading:true,error:null }
    case QUIZ_ACTION_TYPES.CHECK_ANSWER_SUCCESS:
      return { ...state,startTime:payload.startTime,isLoading:false,currentQuestionNo:payload.currentQuestionNo ,error:null }
    case QUIZ_ACTION_TYPES.CHECK_ANSWER_FAILURE:
      return { ...state,isLoading:false,error:payload }
    case USER_ACTION_TYPES.SIGN_OUT_SUCCESS:
      return { ...state, ...INITIAL_STATE };
    case QUIZ_ACTION_TYPES.SUBMIT_QUIZ_SUCCESS:
      return {...state,isLoading:false,error:null,quizDetails:null,currentQuestionNo:0}
    default:
      return state;
  }
};
