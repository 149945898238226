import axios from "axios";
import { BASEURL } from "../baseurl";
export const loginUser = async ({ idToken }) => {
  try {
    const response = await axios.post(
      `${BASEURL}/api/auth/login`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return response.data.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const registerUserService = async ({ idToken, payload }) => {
  try {
    const response = await axios.post(`${BASEURL}/api/user/register`, payload, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    console.log(response.data.data);

    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const fetchCurrentUserScoreService = async ({ idToken }) => {
  try {
    const response = await axios.get(`${BASEURL}/api/user/score`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    console.log(response);
    return response.data.data.points;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const fetchCurrentSubmissionService = async ({ idToken }) => {
  try {
    const response = await axios.get(`${BASEURL}/api/quiz/week/submission`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return response.data.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const playQuizService = async ({ idToken }) => {
  try {
    const response = await axios.post(
      `${BASEURL}/api/quiz/week/play`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return response.data.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const submitQuizService = async ({ idToken, payload }) => {
  try {
    const response = await axios.post(
      `${BASEURL}/api/quiz/week/submit`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return response.data.data;
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

export const checkAnswerService = async ({ idToken, payload }) => {
  try {
    const response = await axios.post(
      `${BASEURL}/api/quiz/week/check`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const fetchLeaderboardService = async ({ idToken }) => {
  try {
    const response = await axios.get(`${BASEURL}/api/user/leaderboard/`, {
      params: {
        skip: 0,
      },
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
