import React from "react";
import UserSm from "../../Assets/images/user-sm.png";
const Accordion = ({ user }) => {
  return (
    <div className="flex mb-3 last:mb-0 items-center w-full bg-transparent-gray p-2 shadow-lg">
      <div className="border border-retro-black rounded-full px-2 md:pl-4 md:pr-2 py-1 w-fit flex items-center">
        <span className="flex-1 pr-4 text-md font-extrabold">{user?.rank}</span>
        <img className="w-4 md:w-8" src={UserSm} alt="profile-pic" />
      </div>
      <div className="flex-2 text-xs md:text-md font-extrabold text-retro-black ml-10 flex-1 whitespace-no-wrap">
        {user?.userName}
      </div>
      <div className="text-xs md:text-md font-extrabold text-green-500 mr-8">
        {user?.points.toFixed(2)}
      </div>
    </div>
  );
};

export default Accordion;
