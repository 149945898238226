import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetchLeaderboardService } from "../../utils/api/api.utils";
import { getCurrentUserToken } from "../../utils/firebase/firebase.util";
import { fetchLeaderboardFailure, fetchLeaderboardSuccess } from "./leaderboard.action";
import { LEADERBOARD_ACTION_TYPES } from "./leaderboard.action.types";

export function* fetchLeaderboard(){
    try {
        const idToken = yield call(getCurrentUserToken);
        const leaderboard = yield call(fetchLeaderboardService, { idToken: idToken });
        yield put(fetchLeaderboardSuccess(leaderboard));
    }
    catch(error){
        yield put(fetchLeaderboardFailure(error));
    }
}

export function* fetchLeaderboardOnStart() {
    yield takeLatest(LEADERBOARD_ACTION_TYPES.FETCH_LEADERBOARD_START, fetchLeaderboard);
}



export function* leaderboardSagas() {
    yield all([call(fetchLeaderboardOnStart)])
}