import React, { useEffect, useState } from 'react'
import axios from "axios";
import { BASEURL } from '../../utils/baseurl';
const HowToPlay = () => {
  const [content,setContent] = useState(null)
  let responseHtml = "";
  useEffect(() => {
    (async function(){
      const response = await axios.post( `${BASEURL}/api/site/content`,{
        url:"https://help.friftysearch.com/how-to-play"
      });
      responseHtml = response.data.data;
      responseHtml = responseHtml.replace(/<header.*?>.*?<\/header>/ig,'');
      setContent(responseHtml)
    }
    )()  
  },[])
  
  return (
    <div>
        <div dangerouslySetInnerHTML={{ __html: content}}></div>
    </div>
  )
}

export default HowToPlay